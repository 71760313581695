import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import SEO from "../components/seo"

import TabBar from "../components/policyTabs"
import Container from "../components/container"

export default function Rfunction({ location }) {
  return (
    <Page>
      <SEO title="Rewards Terms and Conditions" />
      <Container>
        <h1 className="tandc">Terms and Conditions</h1>
      </Container>
      <TabBar location={location} />
      <Container>
        <div className="text-container">
          <h1>Rewards Terms and Conditions</h1>
          <h2>1. Preamble</h2>

          <h2>2. Introduction</h2>

          <p>
            2.1 The Program allows customers to receive a percentage of the
            total purchase price of their booking as a reward in the form of
            bitcoin (the “Reward”).
          </p>
          <p>
            2.2 The Reward percentage received is dependant on the customer’s
            Status Tier, which is automatically upgraded with the accumulation
            of Status Points.
          </p>
          <p>
            2.3 Status Points are earned by purchasing and completing Eligible
            Bookings.
          </p>
          <h2>3. Eligible Bookings</h2>

          <p>
            3.1 For a booking to be an Eligible Booking for the purposes of the
            Program, the following requirements must be met:
          </p>

          <p>
            (i) The booking must be booked and purchased via the Site, and;
            <br />
            (ii) The customer must complete the flight or stay. For flights, the
            Reward will be awarded seven (7) days after the customer has
            completed the final leg of the flight. For accommodation, the Reward
            will be awarded seven (7) days after the customer has completed
            their stay.
          </p>
          <p>
            3.2 The Reward will not be awarded before the reservation is
            considered an Eligible Booking, as per the above requirements. If
            the reservation is cancelled, the customer will not receive the
            Reward.
          </p>
          <p>
            3.3 If a customer receives an upgrade to their booking by means
            other than paying the full fare difference between the original and
            new booking, only the Reward and Status Points equivalent to the
            price of the original booking will be awarded.
          </p>
          <h2>4. Status Points</h2>

          <p>
            4.1 Status Points are earned once an Eligible Booking is completed.
          </p>
          <p>
            4.2 One (1) Status Point is awarded for every AU$10 spent on an
            Eligible Booking.
          </p>
          <p>
            4.3 Status Points are valid from the day the booking is made and
            expire twelve (12) months after the day of booking.
          </p>

          <p>
            4.5 Where Status Points have been awarded to a customer’s account
            through a breach of these Terms and Conditions, or where Status
            Points were incorrectly issued to a customer, TravelbyBit reserves
            the right to reverse the issuance of Status Points to the customer’s
            account.
          </p>
          <h2>5. Status Tiers</h2>

          <p>
            5.1 The customer’s Status is determined based on the amount of
            Status Points accumulated over the preceding 365 days (the “Benefit
            Period”).
          </p>
          <p>
            5.2 The Benefit Period uses a rolling qualification approach,
            whereby only Status Points earned within the preceding 365 days
            contribute to determining the customer’s Status Tier.
          </p>
          <p>
            5.3 A customer will progress to the next Status once the customer
            has accumulated the Status Points required over the Benefit Period.
          </p>
          <p>5.4 The Status Tiers are shown in Table 1 below:</p>
          <h3>Table 1. Status Tiers</h3>
          <table>
            <thead>
              <tr>
                <th>Status</th>
                <th>Titanium</th>
                <th>Platinum</th>
                <th>Gold</th>
                <th>Silver</th>
                <th>Bronze</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Rewards per hotel booking</td>
                <td>5%</td>
                <td>4%</td>
                <td>3%</td>
                <td>2%</td>
                <td>1%</td>
              </tr>
              <tr>
                <td>Rewards per flight booking</td>
                <td>$10.50</td>
                <td>$5.50</td>
                <td>$3.50</td>
                <td>$2.50</td>
                <td>$1.50</td>
              </tr>
              <tr>
                <td>Status Required</td>
                <td>9000</td>
                <td>3500</td>
                <td>1000</td>
                <td>200</td>
                <td>0</td>
              </tr>
              <tr>
                <td>Status Maintenance</td>
                <td>7200</td>
                <td>2800</td>
                <td>800</td>
                <td>160</td>
                <td>0</td>
              </tr>
            </tbody>
          </table>

          <p>
            5.5 When a customer creates a TravelbyBit account, the customer will
            automatically be assigned Bronze Status and will immediately be able
            to benefit from a 1% Reward on hotel bookings.
          </p>
          <p>
            5.6 For a customer to maintain their Status, the customer must
            accumulate the necessary Status Points within the Benefit Period in
            accordance with the applicable Status Maintenance requirements
            outlined in Table 1. If the Status Points accumulated over the
            Benefit Period do not meet the Status Maintenance, the customer’s
            Status will be reverted to the Status that corresponds to the
            attained Status Points under the Status Maintenance during that
            Benefit Period.
          </p>
          <p>
            5.7 If a customer’s Status Points expire such that there are no
            Status Points remaining for the Benefit Period, this will not result
            in the closure of the customer’s account.
          </p>
          <h2>6. Rewards and Withdrawals</h2>

          <p>
            6.1 The maximum Reward that can be received per Eligible Booking is
            5% for hotel bookings and 1% for flight bookings on the Site.
          </p>
          <p>
            6.2 The customer’s Reward balance is reflected in the customer’s
            account summary.
          </p>
          <p>
            6.3 The customer can withdraw their Reward balance to a digital
            wallet designated by the customer once the Reward balance is
            equivalent to, or greater than, AU$25. The Reward balance will be
            converted to bitcoin at a favourable rate selected at TravelbyBit’s
            discretion.
          </p>
          <h2>7. Changes to the Program and User Accounts</h2>

          <p>
            7.1 TravelbyBit may, at its discretion, temporarily or permanently
            discontinue the Program or a customer’s ability to participate in
            The Program.
          </p>
          <p>
            7.2 If a customer is found to be acting in breach of these Terms and
            Conditions, or in an illegal, deceptive or fraudulent manner,
            TravelbyBit reserves the right to render the customer's Reward
            balance void and disqualify the customer from receiving Reward in
            the future.
          </p>
          <p>
            7.3 To determine if any such breach has occurred with respect to
            referral activities, TravelbyBit reserves the right to investigate
            all referral activities and make a decision based on these findings.
          </p>
          <h2>8. Updates to these Terms and Conditions</h2>

          <p>
            8.2 Any changes will be immediately effective from the time the
            changes are published on the website. Changes can be made without
            prior notice and without externally communicating these changes
            outside of the webpage where these Terms are displayed on the Site.
            Participation in the Program following any changes will be deemed to
            constitute acceptance of the updated Terms and Conditions.
          </p>
          <p>
            8.3 If you believe that your Reward balance is inaccurate or has not
            been updated, please contact us via the {` `}
            <Link to="/contact-us">support page</Link> and provide us with any
            relevant information in relation to your account. The decision
            regarding the balance will be made at TravelbyBit’s discretion and
            will be final.
          </p>
          <h2>9. Severability</h2>

          <p>
            9.1 Where a provision or part of a provision of the Program is
            deemed to be void, invalid or unenforceable, that provision or part
            of the provision will be withdrawn in that instance. The
            enforceability of the remaining provisions will not be affected.
          </p>
          <h2>10. Jurisdiction</h2>

          <p>
            10.1 These Terms and Conditions are subject to Australian law, and
            nothing contained within these Terms and Conditions, is intended to
            restrict or exclude the rights of a customer under the Australian
            Consumer Law.
          </p>
          <p>
            10.2 TravelbyBit makes no guarantees, warranties, or representations
            of any kind in relation to the provision of Travel Credits, except
            where a particular guarantee or warranty cannot be excluded under
            applicable law.
          </p>
          <p>
            10.3 Any dispute or claim arising out of or in connection with these
            terms shall be subject to the non-exclusive jurisdiction of the
            courts of Australia.
          </p>
        </div>
      </Container>
    </Page>
  )
}

const Page = styled.div`
  min-height: 100vh;

  @media only screen and (max-width: 1169px) {
    padding: 0 1rem;
  }

  p {
    margin-bottom: 20px;
    line-height: 1.6666;
    font-size: 0.9em;
  }

  table {
    color: #424242;
    font-size: 14px;
    margin-bottom: 30px;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: left;
    width: 100%;

    th,
    td {
      padding: 10px 5px;
      border: 1px solid #aaa;
    }

    th {
      background: #f4f5f7;
      width: 15%;
      font-weight: bold;

      &:first-child {
        width: 20%;
      }
    }
  }
`
